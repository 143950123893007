<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('property_argue_title').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('property_argue_title').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: $store.getters.getField('property_argue_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body" ref="property_argue">
        <div class="form-group__body__radio">
          <label class="form-group__radio">
            <input type="radio" v-model="stepForm.property_argue.value" :value="1" @change="setNull(['properties'])">
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Да</span>
          </label>
          <label class="form-group__radio">
            <input type="radio" v-model="stepForm.property_argue.value" :value="0" @change="setNull(['properties'])">
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Нет</span>
          </label>
        </div>
        <span class="form-group__message__error" v-if="stepForm.property_argue.message">
          {{ stepForm.property_argue.message }}
        </span>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.property_argue.value === 1">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('jointly_acquired_property_title').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('jointly_acquired_property_title').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: $store.getters.getField('jointly_acquired_property_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body">
        <div class="form-group__multiple" ref="properties" v-for="(property, index) in stepForm.properties.value" :key="index">
          <div class="_row">
            <div class="_col-8">
              <VSelect
                label="title"
                v-model="property.type.value"
                :options="propertyTypes"
                :placeholder="$store.getters.getField('properties_type').title"
                :filterable="false" @input="propTypeChange(index)"
                @search:focus="validatePropertyTypes(property.type.value)"
                @search:blur="validatePropertyTypes(property.type.value)"
              />
              <span class="form-group__message__error" v-if="validator.type && !property.type.message">
                Поле обязательно для заполнения
              </span>
              <span class="form-group__message__error" v-if="!property.type.value && property.type.message">
                {{ property.type.message }}
              </span>
            </div>
            <template v-if="property.type.value">
              <div class="_col-4" v-show="property.type.value.id === 4">
                <VSelect
                  label="title"
                  v-model="property.estate_type.value"
                  :options="estateTypes"
                  :placeholder="$store.getters.getField('properties_estate_type').title"
                  :filterable="false"
                  @search:focus="validateEstateTypes(property.estate_type.value)"
                  @search:blur="validateEstateTypes(property.estate_type.value)"
                />
                <span class="form-group__message__error" v-if="validator.estateType && !property.estate_type.message">
                  Поле обязательно для заполнения
                </span>
                <span
                  class="form-group__message__error"
                  v-if="!property.estate_type.value && property.estate_type.message">
                  {{ property.estate_type.message }}
                </span>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 4">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :required="true"
                    :title="$store.getters.getField('properties_estate_id').title"
                    :placeholder="$store.getters.getField('properties_estate_id').title"
                    v-model="property.estate_id.value"
                  >
                  <span class="form-group__message__error" v-if="!property.estate_id.message">
                    {{ errors[0] }}
                  </span>
                    <span
                      class="form-group__message__error"
                      v-if="!property.estate_id.value && property.estate_id.message"
                    >
                      {{ property.estate_id.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-8" v-show="property.type.value.id === 4">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :required="true"
                    :title="$store.getters.getField('properties_estate_address').title"
                    :placeholder="$store.getters.getField('properties_estate_address').title"
                    v-model="property.estate_address.value"
                  >
                  <span class="form-group__message__error" v-if="!property.estate_address.message">
                    {{ errors[0] }}
                  </span>
                    <span
                      class="form-group__message__error"
                      v-if="!property.estate_address.value && property.estate_address.message"
                    >
                      {{ property.estate_address.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 4">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :type="'number'"
                    :required="true"
                    :inputmode="'decimal'"
                    :min="0"
                    :title="$store.getters.getField('properties_estate_area').title"
                    :placeholder="$store.getters.getField('properties_estate_area').title"
                    v-model="property.estate_area.value"
                  >
                    <span class="form-group__message__error" v-if="!property.estate_area.message">
                      {{ errors[0] }}
                    </span>
                    <span
                      class="form-group__message__error"
                      v-if="!property.estate_area.value && property.estate_area.message"
                    >
                      {{ property.estate_area.message }}
                  </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 5">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :required="true"
                    :title="$store.getters.getField('properties_auto_manufacturer').title"
                    :placeholder="$store.getters.getField('properties_auto_manufacturer').title"
                    v-model="property.auto_manufacturer.value"
                  >
                    <span class="form-group__message__error" v-if="!property.auto_manufacturer.message">
                      {{ errors[0] }}
                    </span>
                    <span
                      class="form-group__message__error"
                      v-if="!property.auto_manufacturer.value && property.auto_manufacturer.message"
                    >
                      {{ property.auto_manufacturer.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 5">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :required="true"
                    :title="$store.getters.getField('properties_auto_id').title"
                    :placeholder="$store.getters.getField('properties_auto_id').title"
                    v-model="property.auto_id.value"
                  >
                    <span class="form-group__message__error" v-if="!property.auto_manufacturer.message">
                      {{ errors[0] }}
                    </span>
                    <span class="form-group__message__error" v-if="!property.auto_id.value && property.auto_id.message">
                      {{ property.auto_id.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 5">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    v-mask="'####'"
                    :required="true"
                    :title="$store.getters.getField('properties_auto_year').title"
                    :placeholder="$store.getters.getField('properties_auto_year').title"
                    v-model="property.auto_year.value"
                  >
                    <span class="form-group__message__error" v-if="!property.auto_year.message">
                      {{ errors[0] }}
                    </span>
                    <span
                      class="form-group__message__error"
                      v-if="!property.auto_year.value && property.auto_year.message"
                    >
                      {{ property.auto_year.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 5">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :required="true"
                    :title="$store.getters.getField('properties_auto_vin').title"
                    :placeholder="$store.getters.getField('properties_auto_vin').title"
                    v-model="property.auto_vin.value"
                  >
                    <span class="form-group__message__error" v-if="!property.auto_vin.message">
                      {{ errors[0] }}
                    </span>
                    <span
                      class="form-group__message__error"
                      v-if="!property.auto_vin.value && property.auto_vin.message"
                    >
                      {{ property.auto_vin.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 6">
                <VSelect
                  label="title"
                  v-model="property.bank_account_owner.value"
                  :options="accountOwners"
                  :placeholder="$store.getters.getField('properties_bank_account_owner').title"
                  @search:focus="validateAccountOwners(property.bank_account_owner.value)"
                  @search:blur="validateAccountOwners(property.bank_account_owner.value)"
                  :filterable="false"
                />
                <span
                  class="form-group__message__error"
                  v-if="validator.accountOwners && !property.bank_account_owner.message"
                >
                  Поле обязательно для заполнения
                </span>
                <span
                  class="form-group__message__error"
                  v-if="!property.bank_account_owner.value && property.bank_account_owner.message"
                >
                  {{ property.bank_account_owner.message }}
                </span>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 6">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :required="true"
                    :title="$store.getters.getField('properties_bank_account').title"
                    :placeholder="$store.getters.getField('properties_bank_account').title"
                    v-model="property.bank_account.value"
                  >
                    <span class="form-group__message__error" v-if="!property.bank_account.message">
                      {{ errors[0] }}
                    </span>
                    <span
                      class="form-group__message__error"
                      v-if="!property.bank_account.value && property.bank_account.message"
                    >
                      {{ property.bank_account.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-8" v-show="property.type.value.id === 4">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :type="'number'"
                    :required="true"
                    :inputmode="'decimal'"
                    :min="0"
                    :step="0.01"
                    :title="$store.getters.getField('properties_estate_summ').title"
                    :placeholder="$store.getters.getField('properties_estate_summ').title"
                    v-model="property.estate_summ.value"
                  >
                    <span class="form-group__message__error" v-if="!property.estate_summ.message">{{ errors[0] }}</span>
                    <span
                      class="form-group__message__error"
                      v-if="!property.estate_summ.value && property.estate_summ.message"
                    >
                      {{ property.estate_summ.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 5">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :type="'number'"
                    :required="true"
                    :inputmode="'decimal'"
                    :min="0"
                    :step="0.01"
                    :title="$store.getters.getField('properties_auto_summ').title"
                    :placeholder="$store.getters.getField('properties_auto_summ').title"
                    v-model="property.auto_summ.value"
                  >
                    <span class="form-group__message__error" v-if="!property.auto_summ.message">{{ errors[0] }}</span>
                    <span
                      class="form-group__message__error"
                      v-if="!property.auto_summ.value && property.auto_summ.message"
                    >
                      {{ property.auto_summ.message }}
                  </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 6">
                <DatePickerComponent
                  v-if="mount"
                  v-model="property.bank_date_now.value"
                  @change="bankDate"
                  @click.native="bankDate"
                />
                <span class="form-group__message__error" v-if="validator.bankDate && !property.bank_date_now.message">
                  Поле обязательно для заполнения
                </span>
                <span
                  class="form-group__message__error"
                  v-if="!property.bank_date_now.value && property.bank_date_now.message"
                >
                  {{ property.bank_date_now.message }}
                </span>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 6">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :type="'number'"
                    :required="true"
                    :inputmode="'decimal'"
                    :min="0"
                    :title="$store.getters.getField('properties_bank_summ').title"
                    :placeholder="$store.getters.getField('properties_bank_summ').title"
                    v-model="property.bank_summ.value"
                  >
                    <span class="form-group__message__error" v-if="!property.bank_summ.message">{{ errors[0] }}</span>
                    <span
                      class="form-group__message__error"
                      v-if="!property.bank_summ.value && property.bank_summ.message"
                    >
                      {{ property.bank_summ.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 6">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :required="true"
                    :title="$store.getters.getField('properties_bank_name').title"
                    :placeholder="$store.getters.getField('properties_bank_name').title"
                    v-model="property.bank_name.value"
                  >
                    <span class="form-group__message__error" v-if="!property.bank_name.message">{{ errors[0] }}</span>
                    <span
                      class="form-group__message__error"
                      v-if="!property.bank_name.value && property.bank_name.message"
                    >
                      {{ property.bank_name.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-12" v-show="property.type.value.id === 7">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <textarea
                    :title="$store.getters.getField('properties_other_description').title"
                    :placeholder="$store.getters.getField('properties_other_description').title"
                    class="form-group__input"
                    v-model="property.other_description.value"
                  />
                  <span class="form-group__message__error" v-if="!property.other_description.message">
                    {{ errors[0] }}
                  </span>
                  <span
                    class="form-group__message__error"
                    v-if="!property.other_description.value && property.other_description.message">
                    {{ property.other_description.message }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="_col-4" v-show="property.type.value.id === 7">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :type="'number'"
                    :required="true"
                    :inputmode="'decimal'"
                    :min="0"
                    :step="0.01"
                    :title="$store.getters.getField('properties_other_summ').title"
                    :placeholder="$store.getters.getField('properties_other_summ').title"
                    v-model="property.other_summ.value"
                  >
                    <span class="form-group__message__error" v-if="!property.other_summ.message">{{ errors[0] }}</span>
                    <span
                      class="form-group__message__error"
                      v-if="!property.other_summ.value && property.other_summ.message"
                    >
                      {{ property.other_summ.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <!--   <div class="_col-4" v-show="property.type.value.id === 7">-->
              <!--   <input type="number" inputmode="decimal" min="0" step="0.01" required class="form-group__input" :title="$store.getters.getField('properties_other_count').title" :placeholder="$store.getters.getField('properties_other_count').title" v-model="property.other_count.value">-->
              <!--   <span class="form-group__message__error" v-if="property.other_count.message">{{ property.other_count.message }}</span>-->
              <!--   </div>-->
              <div class="_col-12" v-show="property.type.value !== null && property.type.value.id !== 6">
                <VSelect
                  label="title"
                  v-model="property.to.value"
                  :options="propertyClaimers"
                  :placeholder="$store.getters.getField('properties_to').title"
                  @search:focus="validateTo(property.to.value)"
                  @search:blur="validateTo(property.to.value)"
                  :filterable="false"
                />
                <span class="form-group__message__error" v-if="validator.to && !property.to.message">
                  Поле обязательно для заполнения
                </span>
                <span class="form-group__message__error" v-if="!property.to.value && property.to.message">
                  {{ property.to.message }}
                </span>
              </div>
              <div class="_col-12" v-show="property.type.value !== null && property.type.value.id === 5">
                <VSelect
                  label="title"
                  v-model="property.auto_owner.value"
                  :options="autoOwners"
                  placeholder="Выберите на чье имя оформлен автомобиль"
                  @search:focus="validateAutoOwners(property.auto_owner.value)"
                  @search:blur="validateAutoOwners(property.auto_owner.value)"
                  :filterable="false"
                />
                <span class="form-group__message__error" v-if="validator.auto_owner && !property.auto_owner.message">
                  Поле обязательно для заполнения
                </span>
                <span class="form-group__message__error" v-if="property.auto_owner.message">
                  {{ property.auto_owner.message }}
                </span>
              </div>
            </template>
          </div>
          <div class="form-group__multiple__remove">
            <button class="button button--hollow" type="button" @click="removeProperty(index)">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2V22" stroke="#003366" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M2 12H22" stroke="#003366" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="form-group__multiple__add">
          <button type="button" class="button button--hollow" @click="addProperty()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2V22" stroke="#003366" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M2 12H22" stroke="#003366" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadioStatesIcon from 'components/svg/RadioStates.vue'
import TooltipIcon from 'components/svg/Tooltip.vue'
import InputField from 'components/InputField.vue'
import { updateErrorsMixin } from '@/mixins/updateErrorsMixin.js'
import { resetValidationMixin } from '@/mixins/resetValidationMixin.js'
import { fetchAddressMixin } from '@/mixins/fetchAddressMixin.js'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'DivorceFormStep6',
  mixins: [updateErrorsMixin, resetValidationMixin, fetchAddressMixin, scrollToErrorsMixin],
  components: {
    DatePickerComponent: () => import('@/components/DatePickerComponent.vue'),
    TooltipIcon,
    RadioStatesIcon,
    InputField
  },
  data () {
    return {
      mount: false,
      addresses: [],
      errorsCount: 0,
      propertyTypes: [{
        id: 4,
        title: 'Недвижимость'
      }, {
        id: 5,
        title: 'Автомобиль'
      }, {
        id: 6,
        title: 'Деньги на банковском счете'
      }, {
        id: 7,
        title: 'Иное'
      }],
      accountOwners: [{
        id: 0,
        title: 'Счет открыт на Вас'
      }, {
        id: 1,
        title: 'Счет открыт на супруга/у'
      }],
      autoOwners: [{
        id: 0,
        title: 'Автомобиль оформлен на Вас'
      }, {
        id: 1,
        title: 'Автомобиль оформлен на супруга/у'
      }],
      propertyClaimers: [{
        id: 0,
        title: 'Вам (с выплатой денежной компенсации cупругу/ге)'
      }, {
        id: 1,
        title: 'Супругу/ге (с выплатой денежной компенсации вам)'
      }, {
        id: 2,
        title: 'В равных долях обоим'
      }],
      estateTypes: ['Квартира', 'Комната', 'Дом', 'Земельный участок', 'Машино-место', 'Иное'],
      stepForm: {
        property_argue: {
          value: null,
          message: null
        },
        properties: {
          value: [],
          message: null
        }
      },
      property: {
        type: {
          value: null,
          message: null
        },
        estate_type: {
          value: null,
          message: null
        },
        estate_address: {
          value: null,
          message: null
        },
        estate_id: {
          value: null,
          message: null
        },
        estate_area: {
          value: null,
          message: null
        },
        auto_manufacturer: {
          value: null,
          message: null
        },
        auto_id: {
          value: null,
          message: null
        },
        auto_year: {
          value: null,
          message: null
        },
        auto_vin: {
          value: null,
          message: null
        },
        bank_name: {
          value: null,
          message: null
        },
        bank_account: {
          value: null,
          message: null
        },
        bank_date_now: {
          value: null,
          message: null
        },
        bank_summ: {
          value: null,
          message: null
        },
        estate_summ: {
          value: null,
          message: null
        },
        auto_summ: {
          value: null,
          message: null
        },
        other_summ: {
          value: null,
          message: null
        },
        bank_account_owner: {
          value: null,
          message: null
        },
        other_description: {
          value: null,
          message: null
        },
        other_count: {
          value: null,
          message: null
        },
        to: {
          value: null,
          message: null
        },
        auto_owner: {
          value: null,
          message: null
        }
      },
      validator: {
        type: null,
        estateType: null,
        accountOwners: null,
        to: null,
        auto_owner: null,
        bankDate: null
      }
    }
  },
  mounted () {
    this.mount = true
    if (localStorage.divorceStepForm6) {
      Object.assign(this.stepForm, JSON.parse(localStorage.divorceStepForm6))
    }
  },
  watch: {
    stepForm: {
      handler: function () {
        localStorage.divorceStepForm6 = JSON.stringify((this.stepForm))

        this.stepForm.properties.value.forEach((item, index) => {
          const data = {}
          Object.keys(item).forEach(fld => {
            if (typeof item[fld].value === 'object') {
              if (item[fld].value !== null) {
                data[fld] = item[fld].value.title
              }
            } else {
              if (item[fld].value !== null) {
                if (item[fld].value !== 'property_argue') data[fld] = item[fld].value
              }
            }
          })
        })
        this.$store.dispatch('ADD_DIVORCE_STEPFORM', this.stepForm)
        this.$store.commit(
          'choice_courts_data',
          { key: 'property_argue', value: this.stepForm.property_argue.value }
        )

        this.$store.commit('add_properties_value', this.stepForm.properties.value)
      },
      deep: true
    }
  },
  methods: {
    bankDate () {
      this.validator.bankDate = !this.property.bank_date_now.value
    },
    validatePropertyTypes (value) {
      this.validator.propertiesType = !value
    },
    validateEstateTypes (value) {
      this.validator.estateType = !value
    },
    validateAccountOwners (value) {
      this.validator.accountOwners = !value
    },
    validateTo (value) {
      this.validator.to = !value
    },
    validateAutoOwners (value) {
      this.validator.auto_owner = !value
    },
    validate () {
      // TODO сделать валидацию
      return true
    },
    getData () {
      return this.stepForm
    },
    /**
     * Обнуление полей при скрытии из за radio
     * @param fields
     */
    setNull (fields) {
      fields.forEach(field => {
        if (typeof this.stepForm[field].value === 'object' && this.stepForm[field].value !== null) {
          this.stepForm[field].value = []
        } else {
          this.stepForm[field].value = null
        }
      })
      if (this.stepForm.properties.value.length === 0) {
        this.addProperty()
      }
    },
    addProperty () {
      this.stepForm.properties.value.push(JSON.parse(JSON.stringify(this.property)))
    },
    removeProperty (index) {
      this.stepForm.properties.value.splice(index, 1)
    },
    getErrorsCount () {
      return this.errorsCount
    },
    propTypeChange (index) {
      Object.keys(this.stepForm.properties.value[index]).forEach(key => {
        if (key !== 'type') {
          this.stepForm.properties.value[index][key].value = null
        }
      })
    }
  }
}
</script>
