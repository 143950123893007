<template>
  <div v-if="Object.keys(getData).length">
    <h1 class="page-header">Проверьте данные</h1>
    <div class="form-group">
      <div class="form-group__body">
        <div class="check-data">
          <div class="row check-data__item" v-if="getData.surname && getData.name">
            <span class="col-3 check-data__text-muted">Ваше Ф.И.О.:</span>
            <span class="col-9">
              {{ getData.surname }} {{ getData.name }}
              <span v-if="getData.patronymic">{{ getData.patronymic }}</span>
            </span>
          </div>
          <div class="row check-data__item" v-if="getData.email">
            <span class="col-3 check-data__text-muted">Ваш email:</span>
            <span class="col-9">{{ getData.email }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.address">
            <span class="col-3 check-data__text-muted">Место Вашего жительства(по паспорту):</span>
            <span class="col-9">{{ getData.address }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.mailing_address">
            <span class="col-3 check-data__text-muted">Место фактического пребывания:</span>
            <span class="col-9">{{ getData.mailing_address }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.birthday">
            <span class="col-3 check-data__text-muted">Дата рождения:</span>
            <span class="col-9">{{ formatDate(getData.birthday) }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.birthplace">
            <span class="col-3 check-data__text-muted">Место рождения:</span>
            <span class="col-9">{{ getData.birthplace }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.document_type">
            <span class="col-3 check-data__text-muted">
              <span v-if="getData.document_type === 1">{{getField('document_type_one').title}}</span>
              <span v-else-if="getData.document_type === 2">{{getField('document_type_two').title}}</span>
              <span v-else-if="getData.document_type === 3">{{getField('document_type_three').title}}</span>
              <span v-else>{{getField('document_type_four').title}}</span>:
            </span>
            <span class="col-9" v-if="getData.document_number">{{ getData.document_number }}</span>
          </div>
          <template>
            <div
              class="row check-data__item"
              v-if="getData.spouse_surname && getData.spouse_name"
            >
              <span class="col-3 check-data__text-muted">Ф.И.О. Вашего супруга/ги:</span>
              <span class="col-9">
                {{ getData.spouse_surname }}
                {{ getData.spouse_name }}
                <span v-if="getData.spouse_patronymic">{{ getData.spouse_patronymic }}</span>
              </span>
            </div>
            <div
              class="row check-data__item"
              v-if="getData.spouse_address && getData.spouse_address"
            >
              <span class="col-3 check-data__text-muted">Адрес проживания супруга/ги:</span>
              <span class="col-9">{{ getData.spouse_address }}</span>
            </div>
            <div class="row check-data__item" v-if="getData.spouse_birthday">
              <span class="col-3 check-data__text-muted">Дата рождения супруга/ги (если знаете):</span>
              <span class="col-9">{{ formatDate(getData.spouse_birthday) }}</span>
            </div>
            <div class="row check-data__item" v-if="getData.spouse_birth_place">
              <span class="col-3 check-data__text-muted">Место рождения супруга/ги (если знаете):</span>
              <span class="col-9">{{ getData.spouse_birth_place }}</span>
            </div>
            <div class="row check-data__item" v-if="getData.spouse_work">
              <span class="col-3 check-data__text-muted">Место работы супруга/ги (если знаете):</span>
              <span class="col-9">{{ getData.spouse_work }}</span>
            </div>
          </template>
          <!-- <template v-if="Object.keys(getData).length">
            <div class="row check-data__item" v-if="getData.marriage_date">
              <span class="col-3 check-data__text-muted">Дата регистрации брака:</span>
              <span class="col-9">{{ formatDate(getData.marriage_date) }}</span>
            </div>
            <div class="row check-data__item" v-if="getData.marriage_id">
              <span class="col-3 check-data__text-muted">Номер записи акта о заключении брака:</span>
              <span class="col-9">{{ getData.marriage_id }}</span>
            </div>
            <div class="row check-data__item" v-if="getData.marriage_authority">
              <span class="col-3 check-data__text-muted">
                Полное наименование органа, зарегистрировавшего брак (в творительном падеже):
              </span>
              <span class="col-9">{{ getData.marriage_authority }}</span>
            </div>
          </template> -->
          <div
            v-if="getData.property_argue || getData.have_underage_children" class="showMore"
            @click="showMore = !showMore"
          >
            <span v-if="!showMore">Показать все данные</span>
            <ShowMore class="showMore__icon" v-if="!showMore"/>
            <span v-if="showMore">Свернуть все данные</span>
            <Hide class="showMore__icon" v-if="showMore"/>
          </div>
          <div v-if="getData.property_argue || getData.have_underage_children">
            <div v-if="showMore">
              <div v-if="getData.have_underage_children">
                <div class="row check-data__item">
                  <div class="col-3 check-data__text-muted">Дети:</div>
                  <div class="col-9">
                    <div v-for="(child, index) in getData.underage_children" :key="index" class="check-data__mb">
                      <div class="row">
                        <span class="col-12">
                          <span class="check-data__text-muted">ФИО:</span>
                          <span v-if="child.name.value && child.surname.value">
                            {{ child.surname.value }} {{ child.name.value }}
                            <span v-if="child.patronymic.value">{{ child.patronymic.value }}</span>
                          </span>
                          <span v-else> Заполните ФИО ребёнка!</span>
                        </span>
                      </div>
                      <div class="row">
                        <span class="col-12">
                          <span class="check-data__text-muted">Дата рождения:</span>
                          <span v-if="child.birthday.value">{{ formatDate(child.birthday.value) }}</span>
                          <span v-else> Заполните дату!</span>
                        </span>
                      </div>
                      <div v-if="child.after.value !== null && child.current.value !== null" class="row">
                        <span v-if="child.current.value">Проживает сейчас со мной.</span>
                        <span v-else>Проживает с супругом/ой.</span>
                        <span v-if="child.after.value"> После развода со мной</span>
                        <span v-else> После развода с супругом/ой</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row check-data__item">
                  <div class="col-3 check-data__text-muted">Дети:</div>
                  <div class="col-9">Нет</div>
                </div>
              </div>
              <div v-if="getData.property_argue">
                <div class="row check-data__item">
                  <div class="col-3 check-data__text-muted">Имущество:</div>
                  <div class="col-9">
                    <div v-for="(properties, index) in getData.properties" :key="index" class="check-data__mb">
                      <div v-if="properties.type.value.title === 'Недвижимость'" class="row">
                        <span class="col-12" v-if="properties.type.value.title">{{ properties.type.value.title }}</span>
                        <span class="col-12" v-if="properties.estate_type.value">
                          <span class="check-data__text-muted">Тип:</span> {{ properties.estate_type.value }}
                        </span>
                        <span class="col-12" v-if="properties.estate_address.value">
                          <span class="check-data__text-muted">Адрес:</span> {{ properties.estate_address.value }}
                        </span>
                        <span class="col-12" v-if="properties.estate_area.value">
                          <span class="check-data__text-muted">Площадь:</span> {{ properties.estate_area.value }}
                        </span>
                        <span class="col-12" v-if="properties.estate_id.value">
                          <span class="check-data__text-muted">Кадастровый номер:</span> {{ properties.estate_id.value }}
                        </span>
                        <span class="col-12" v-if="properties.estate_summ.value">
                          <span class="check-data__text-muted">Стоимость:</span> {{ properties.estate_summ.value }} руб.
                        </span>
                        <span class="col-12" v-if="properties.to.value">
                          <span class="check-data__text-muted">Достанется:</span> {{ properties.to.value.title }}
                        </span>
                      </div>
                      <div v-else-if="properties.type.value.title === 'Автомобиль'" class="row">
                        <span class="col-12" v-if="properties.type.value.title">{{ properties.type.value.title }}</span>
                        <span class="col-12" v-if="properties.auto_manufacturer.value">
                          <span class="check-data__text-muted">Марка модель:</span> {{ properties.auto_manufacturer.value }}
                        </span>
                        <span class="col-12" v-if="properties.auto_id.value">
                          <span class="check-data__text-muted">Государственный номер:</span> {{ properties.auto_id.value }}
                        </span>
                        <span class="col-12" v-if="properties.auto_year.value">
                          <span class="check-data__text-muted">Год изготовления:</span> {{ properties.auto_year.value }}
                        </span>
                        <span class="col-12" v-if="properties.auto_vin.value">
                          <span class="check-data__text-muted">VIN:</span> {{ properties.auto_vin.value }}
                        </span>
                        <span class="col-12" v-if="properties.auto_summ.value">
                          <span class="check-data__text-muted">Стоимость:</span> {{ properties.auto_summ.value }} руб.
                        </span>
                        <span class="col-12" v-if="properties.auto_owner.value">
                          <span class="check-data__text-muted">Владелец:</span> {{ properties.auto_owner.value.title }}
                        </span>
                        <span class="col-12" v-if="properties.to.value">
                          <span class="check-data__text-muted">Достанется:</span> {{ properties.to.value.title }}
                        </span>
                      </div>
                      <div v-else-if="properties.type.value.title === 'Деньги на банковском счете'" class="row">
                        <span class="col-12" v-if="properties.type.value.title">{{ properties.type.value.title }}</span>
                        <span class="col-12" v-if="properties.bank_name.value">
                          <span class="check-data__text-muted">Название банка:</span> {{ properties.bank_name.value }}
                        </span>
                        <span class="col-12" v-if="properties.bank_account.value">
                            <span class="check-data__text-muted">Номер счета:</span> {{ properties.bank_account.value }}
                        </span>
                        <span class="col-12" v-if="properties.bank_summ.value">
                          <span class="check-data__text-muted">Сумма денег на счёте:</span>
                          {{ properties.bank_summ.value }} руб.
                        </span>
                        <span class="col-12" v-if="properties.bank_account_owner.value">
                          {{ properties.bank_account_owner.value.title }}
                        </span>
                      </div>
                      <div v-else class="row">
                        <span class="col-12" v-if="properties.type.value.title">{{ properties.type.value.title }}</span>
                        <span class="col-12" v-if="properties.other_description.value">
                          {{ properties.other_description.value }}
                        </span>
                        <span class="col-12" v-if="properties.other_summ.value">
                          <span class="check-data__text-muted">Стоимость:</span> {{ properties.other_summ.value }} руб.
                        </span>
                        <span class="col-12" v-if="properties.to.value">
                          <span class="check-data__text-muted">Достанется:</span> {{ properties.to.value.title }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="getData.aliment_claim" class="row check-data__item">
                <div class="col-3 check-data__text-muted">Алименты:</div>
                <div class="col-9">
                  <span class="col-12" v-if="getData.type">
                    <span class="check-data__text-muted">Тип алиментов:</span>
                    <span v-if="getData.type === 1"> Доля от дохода</span>
                    <span v-else-if="getData.type === 2"> 1/2 прожиточного минимума на ребенка</span>
                    <span v-else-if="getData.type === 3"> В размере прежних сумм на содержание ребенка</span>
                    <span v-else-if="getData.type === 4"> Доля от дохода, но не менее 1/2 прожиточного минимума на ребенка</span>
                    <span v-else-if="getData.type === 5"> Доля от дохода, но не менее прежних сумм на содержание ребенка</span>
                  </span>
                  <span class="col-12" v-if="getData.monthly_income">
                    <span class="check-data__text-muted">Сумма:</span> {{ getData.monthly_income }} руб.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ShowMore from '../../../../components/svg/ShowMore.vue'
import Hide from '../../../../components/svg/Hide.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'checkData',
  components: { ShowMore, Hide },
  data () {
    return {
      showMore: false
    }
  },
  computed: {
    ...mapGetters(['getAlimentStepForm']),
    getData () {
      const data = this.$store.state.divorceStepForm || {}
      return this.parse(data)
    }
  },
  methods: {
    getField (name) {
      return this.$store.getters.getField(name)
    },
    parse (data) {
      const forms = {}
      for (const form in data) {
        if (data[form].value) {
          switch (form) {
            case 'spouse_address':
            case 'address' :
            case 'mailing_address' : {
              forms[form] = data[form].value.value
              break
            }
            case 'children' : {
              forms[form] = {}
              Object.keys(data[form].value).forEach(childCount => {
                forms[form][childCount] = {}
                for (const item in data[form].value[childCount]) {
                  forms[form][childCount][item] = data[form].value[childCount][item].value
                }
              })
              break
            }
            case 'court_id': {
              forms[form] = data[form].value.id
              break
            }
            default : {
              forms[form] = data[form].value
            }
          }
        }
      }
      return forms
    },
    formatDate (date) {
      return moment(date, 'YYYY.MM.DD').format('DD.MM.YYYY')
    }
  }
}
</script>
