<template>
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 5L5 1L1 5" stroke="#003366"/>
  </svg>
</template>

<script>
export default {
  name: 'Hide'
}
</script>
