<template>
  <div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('have_underage_children').title }}</span>
        <span class="form-group__tooltip" v-if="$store.getters.getField('have_underage_children').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: $store.getters.getField('have_underage_children').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body" ref="have_underage_children">
        <div class="form-group__body__radio">
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.have_underage_children.value"
              @click="setShowAliments(true)"
              :value="1"
              @change="setNull(['divorce_agreement', 'live_underage_children', 'parent_underage_children', 'underage_children', 'spouse_live_same_place', 'unable_participation_court'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Да</span>
          </label>
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.have_underage_children.value" @click="setShowAliments(false)"
              :value="0"
              @change="setNull(['divorce_agreement', 'live_underage_children', 'parent_underage_children', 'underage_children', 'spouse_live_same_place', 'unable_participation_court'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Нет</span>
          </label>
        </div>
        <span
          class="form-group__message__error"
          v-if="stepForm.have_underage_children.value === null && stepForm.have_underage_children.message"
        >
          {{ stepForm.have_underage_children.message }}
        </span>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.have_underage_children.value === 0">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('divorce_agreement').title }}</span>
        <span class="form-group__tooltip" v-if="$store.getters.getField('divorce_agreement').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: $store.getters.getField('divorce_agreement').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body" ref="divorce_agreement">
        <div class="form-group__body__radio">
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.divorce_agreement.value"
              :value="1"
              @change="setNull(['live_underage_children', 'spouse_live_same_place', 'unable_participation_court'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Да</span>
          </label>
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.divorce_agreement.value"
              :value="0"
              @click.prevent="$swal('В таком случае, обращение в суд не допускается, нужно совместное обращение в ЗАГС')"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Нет</span>
          </label>
        </div>
        <span
          class="form-group__message__error"
          v-if="stepForm.divorce_agreement.value === null && stepForm.divorce_agreement.message">
          {{ stepForm.divorce_agreement.message }}
        </span>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.have_underage_children.value === 1">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('live_underage_children').title }}</span>
        <span class="form-group__tooltip" v-if="$store.getters.getField('live_underage_children').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: $store.getters.getField('live_underage_children').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body" ref="live_underage_children">
        <div class="form-group__body__radio">
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.live_underage_children.value"
              :value="1"
              @change="setNull(['spouse_live_same_place', 'unable_participation_court'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Да</span>
          </label>
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.live_underage_children.value"
              :value="0"
              @change="setNull(['spouse_live_same_place', 'unable_participation_court'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Нет</span>
          </label>
        </div>
        <span class="form-group__message__error"
              v-if="stepForm.live_underage_children.value === null && stepForm.live_underage_children.message"
        >
          {{ stepForm.live_underage_children.message }}
        </span>
      </div>
    </div>
    <div class="form-group"
         v-if="stepForm.have_underage_children.value !== null && stepForm.live_underage_children.value === 0">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('spouse_live_same_place').title }}</span>
        <span class="form-group__tooltip" v-if="$store.getters.getField('spouse_live_same_place').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: $store.getters.getField('spouse_live_same_place').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body" ref="spouse_live_same_place">
        <div class="form-group__body__radio">
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.spouse_live_same_place.value"
              :value="1"
              @change="setNull(['unable_participation_court'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Да</span>
          </label>
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.spouse_live_same_place.value"
              :value="0"
              @change="setNull(['unable_participation_court'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Нет</span>
          </label>
        </div>
        <span class="form-group__message__error"
              v-if="stepForm.spouse_live_same_place.value === null && stepForm.spouse_live_same_place.message">
          {{ stepForm.spouse_live_same_place.message }}
        </span>
      </div>
    </div>
    <div class="form-group"
         v-if="stepForm.have_underage_children.value !== null && stepForm.live_underage_children.value !== null && stepForm.spouse_live_same_place.value !== null && stepForm.spouse_live_same_place.value === 0">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('unable_participation_court').title }}</span>
        <span class="form-group__tooltip" v-if="$store.getters.getField('unable_participation_court').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: $store.getters.getField('unable_participation_court').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body" ref="unable_participation_court">
        <div class="form-group__body__radio">
          <label class="form-group__radio">
            <input type="radio" v-model="stepForm.unable_participation_court.value" :value="1">
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Да</span>
          </label>
          <label class="form-group__radio">
            <input type="radio" v-model="stepForm.unable_participation_court.value" :value="0">
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Нет</span>
          </label>
        </div>
        <span class="form-group__message__error"
              v-if="stepForm.unable_participation_court.value === null && stepForm.unable_participation_court.message"
        >
          {{ stepForm.unable_participation_court.message }}
        </span>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.have_underage_children.value === 1">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('parent_underage_children').title }}</span>
        <span class="form-group__tooltip" v-if="$store.getters.getField('parent_underage_children').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: $store.getters.getField('parent_underage_children').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body" ref="parent_underage_children">
        <div class="form-group__body__radio">
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.parent_underage_children.value"
              :value="1"
              @change="setNull(['underage_children'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Да</span>
          </label>
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.parent_underage_children.value"
              :value="0"
              @change="setNull(['underage_children'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Нет</span>
          </label>
        </div>
        <span
          class="form-group__message__error"
          v-if="stepForm.parent_underage_children.value === null && stepForm.parent_underage_children.message"
        >
          {{ stepForm.parent_underage_children.message }}
        </span>
      </div>
    </div>
    <div
      class="form-group"
      v-if="stepForm.have_underage_children.value !== null && stepForm.parent_underage_children.value !== null"
    >
      <div class="form-group__header" v-if="stepForm.parent_underage_children.value === 1">
        <span class="form-group__header__title">{{ $store.getters.getField('fio_children_title').title }}</span>
        <span class="form-group__tooltip" v-if="$store.getters.getField('fio_children_title').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: $store.getters.getField('fio_children_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__header" v-else>
        <span class="form-group__header__title">{{ $store.getters.getField('fio_children_by_consent_title').title }}</span>
        <span class="form-group__tooltip" v-if="$store.getters.getField('fio_children_by_consent_title').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: $store.getters.getField('fio_children_by_consent_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body">
        <div class="row" id="underage_children">
          <div class="form-group__multiple" ref="underage_children" v-for="(children, index) in stepForm.underage_children.value" :key="index">
            <div class="_row">
              <div class="_col-4">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :required="true"
                    :title="$store.getters.getField('underage_children_surname').title"
                    :placeholder="$store.getters.getField('underage_children_surname').title"
                    v-model="children.surname.value"
                  >
                    <span class="form-group__message__error" v-if="!children.surname.message">{{ errors[0] }}</span>
                    <span class="form-group__message__error" v-if="!children.surname.value && children.surname.message">
                      {{ children.surname.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :required="true"
                    :title="$store.getters.getField('underage_children_name').title"
                    :placeholder="$store.getters.getField('underage_children_name').title"
                    v-model="children.name.value"
                  >
                    <span class="form-group__message__error" v-if="!children.name.message">{{ errors[0] }}</span>
                    <span class="form-group__message__error" v-if="!children.name.value && children.name.message">
                      {{ children.name.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4">
                <InputField
                  :required="true"
                  :title="$store.getters.getField('underage_children_patronymic').title"
                  :placeholder="$store.getters.getField('underage_children_patronymic').title"
                  v-model="children.patronymic.value"
                >
                  <span
                    class="form-group__message__error"
                    v-if="!children.patronymic.value && children.patronymic.message"
                  >
                    {{ children.patronymic.message }}
                  </span>
                </InputField>
              </div>
              <div class="_col-4">
                <DatePickerComponent
                  v-if="mount"
                  v-model="children.birthday.value"
                  @blur="birthday(children.birthday.value, index)"
                />
                <span class="form-group__message__error" v-if="children.validator && !children.birthday.message">
                  Поле обязательно для заполнения
                </span>
                <span class="form-group__message__error" v-if="!children.birthday.value && children.birthday.message">
                  {{ children.birthday.message }}
                </span>
              </div>
              <div class="_col-4">
                <div class="form-group__radio__col-no-margin">
                  <label class="form-group__radio">
                    <input type="radio" v-model="children.current.value" :value="1">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">{{ $store.getters.getField('underage_children_current_true').title }}</span>
                    <span class="form-group__tooltip" v-if="$store.getters.getField('underage_children_current_true').description">
                      <i class="icon" aria-describedby="tooltip"
                         v-tooltip="{ content: $store.getters.getField('underage_children_current_true').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                         tabindex="1">
                        <TooltipIcon/>
                      </i>
                    </span>
                  </label>
                  <label class="form-group__radio">
                    <input type="radio" v-model="children.current.value" :value="0">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">
                      {{ $store.getters.getField('underage_children_current_false').title }}
                    </span>
                    <span
                      class="form-group__tooltip"
                      v-if="$store.getters.getField('underage_children_current_false').description"
                    >
                      <i class="icon" aria-describedby="tooltip"
                         v-tooltip="{ content: $store.getters.getField('underage_children_current_false').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                         tabindex="1">
                        <TooltipIcon/>
                      </i>
                    </span>
                  </label>
                </div>
                <span
                  class="form-group__message__error"
                  v-if="children.current.value === null && children.current.message"
                >
                  {{ children.current.message }}
                </span>
              </div>
              <div class="_col-4">
                <div class="form-group__radio__col-no-margin">
                  <label class="form-group__radio">
                    <input type="radio" v-model="children.after.value" :value="1">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">{{ $store.getters.getField('underage_children_after_true').title }}</span>
                    <span class="form-group__tooltip" v-if="$store.getters.getField('underage_children_after_true').description">
                      <i class="icon" aria-describedby="tooltip"
                         v-tooltip="{ content: $store.getters.getField('underage_children_after_true').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                         tabindex="1">
                        <TooltipIcon/>
                      </i>
                    </span>
                  </label>
                  <label class="form-group__radio">
                    <input type="radio" v-model="children.after.value" :value="0">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">{{ $store.getters.getField('underage_children_after_false').title }}</span>
                    <span class="form-group__tooltip" v-if="$store.getters.getField('underage_children_after_false').description">
                      <i class="icon" aria-describedby="tooltip"
                         v-tooltip="{ content: $store.getters.getField('underage_children_after_false').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                         tabindex="1">
                        <TooltipIcon/>
                      </i>
                    </span>
                  </label>
                </div>
                <span class="form-group__message__error" v-if="children.after.value === null && children.after.message">
                  {{ children.after.message }}
                </span>
              </div>
            </div>
            <div class="form-group__multiple__remove">
              <button class="button button--hollow" type="button" @click="removeChildren(index)">
                <PlusIcon/>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group__multiple__add">
          <button type="button" class="button button--hollow" @click="addChildren()">
            <span>Добавить ребёнка</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadioStatesIcon from 'components/svg/RadioStates.vue'
import PlusIcon from 'components/svg/Plus.vue'
import TooltipIcon from 'components/svg/Tooltip.vue'
import InputField from 'components/InputField.vue'
import petrovich from 'petrovich'
import { updateErrorsMixin } from '@/mixins/updateErrorsMixin.js'
import { resetValidationMixin } from '@/mixins/resetValidationMixin.js'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'DivorceFormStep4',
  mixins: [updateErrorsMixin, resetValidationMixin, scrollToErrorsMixin],
  components: {
    DatePickerComponent: () => import('@/components/DatePickerComponent.vue'),
    TooltipIcon,
    PlusIcon,
    RadioStatesIcon,
    InputField
  },
  data () {
    return {
      mount: false,
      errorsCount: 0,
      stepForm: {
        have_underage_children: {
          value: null,
          message: null
        },
        divorce_agreement: {
          value: null,
          message: null
        },
        live_underage_children: {
          value: null,
          message: null
        },
        spouse_live_same_place: {
          value: null,
          message: null
        },
        unable_participation_court: {
          value: null,
          message: null
        },
        parent_underage_children: {
          value: null,
          message: null
        },
        underage_children: {
          value: [],
          message: null
        }
      },
      underageChildrenExampleFields: {
        surname: {
          value: '',
          message: null
        },
        name: {
          value: '',
          message: null
        },
        patronymic: {
          value: '',
          message: null
        },
        surname_rod: {
          value: '',
          message: null
        },
        name_rod: {
          value: '',
          message: null
        },
        patronymic_rod: {
          value: '',
          message: null
        },
        birthday: {
          value: null,
          message: null
        },
        current: {
          value: null,
          message: null
        },
        after: {
          value: null,
          message: null
        },
        sex: {
          value: null,
          message: null
        },
        validator: false
      }
    }
  },
  mounted () {
    this.mount = true
    if (localStorage.divorceStepForm4) {
      Object.assign(this.stepForm, JSON.parse(localStorage.divorceStepForm4))
      if (this.stepForm.have_underage_children.value) {
        this.setShowAliments(true)
      }
    }
  },
  watch: {
    stepForm: {
      handler: function () {
        this.setGenitive()
        this.$store.dispatch('ADD_DIVORCE_STEPFORM', this.stepForm)
        localStorage.divorceStepForm4 = JSON.stringify((this.stepForm))
        if (this.stepForm.have_underage_children.value) this.setShowAliments(true)
        this.$store.commit('choice_courts_data', {
          key: 'parent_underage_children',
          value: this.stepForm.parent_underage_children.value
        })
        this.$store.commit('choice_courts_data', {
          key: 'live_underage_children',
          value: this.stepForm.live_underage_children.value
        })
        this.$store.commit('choice_courts_data', {
          key: 'unable_participation_court',
          value: this.stepForm.unable_participation_court.value
        })
      },
      deep: true
    }
  },
  methods: {
    birthday (value, index) {
      this.stepForm.underage_children.value[index].validator = !value
    },
    setGenitive () {
      this.stepForm.underage_children.value.forEach(children => {
        const genitive = petrovich({
          last: children.surname.value,
          first: children.name.value,
          middle: children.patronymic.value
        }, 'genitive')

        children.surname_rod.value = genitive.last
        children.name_rod.value = genitive.first
        children.patronymic_rod.value = genitive.middle
        children.sex.value = genitive.gender
      })
    },
    setShowAliments (bool) {
      this.$emit('show', bool)
    },
    /**
     * Валидация данных этого шага формы
     */
    validate () {
      // TODO сделать валидацию
      return true
    },
    /**
     * Получение данных из этого шага формы
     */
    getData () {
      this.setGenitive()
      return this.stepForm
    },
    /**
     * Обнуление полей при скрытии из за radio
     * @param fields
     */
    setNull (fields) {
      fields.forEach(field => {
        if (typeof this.stepForm[field].value === 'object' && this.stepForm[field].value !== null) {
          this.stepForm[field].value = []
        } else {
          this.stepForm[field].value = null
        }
      })
      if (this.stepForm.underage_children.value.length === 0) {
        this.addChildren()
      }
    },
    /**
     * Добавление полей в массив в который указываются дети
     */
    addChildren () {
      this.stepForm.underage_children.value.push(JSON.parse(JSON.stringify(this.underageChildrenExampleFields)))
    },
    /**
     * Удаление полей и массива в который указываются дети
     * @param index - Number
     */
    removeChildren (index) {
      this.stepForm.underage_children.value.splice(index, 1)
    },
    getErrorsCount () {
      return this.errorsCount
    }
  }
}
</script>
