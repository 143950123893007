<template>
  <div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">
          По Вашему иску необходимо оплатить госпошлину в размере: {{ countProperties }} ₽
        </span>
      </div>
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('fees_cancellation').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('fees_cancellation').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: $store.getters.getField('fees_cancellation').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body" ref="fees_cancellation">
        <div class="form-group__body__radio">
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.fees_cancellation.value"
              :value="1"
              @change="setNull(['fees_amount'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Да</span>
          </label>
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.fees_cancellation.value"
              :value="0"
              @change="setNull(['fees_amount'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Нет</span>
          </label>
        </div>
        <span class="form-group__message__error" v-if="stepForm.fees_cancellation.message">
          {{ stepForm.fees_cancellation.message }}
        </span>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.fees_cancellation.value === 1">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('fees_amount_title').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('fees_amount_title').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: $store.getters.getField('fees_amount_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body" ref="fees_amount">
        <div class="_row">
          <div class="_col-12">
            <InputField
              :type="'number'"
              :required="true"
              :inputmode="'decimal'"
              :min="0"
              :title="$store.getters.getField('fees_amount').title"
              :placeholder="$store.getters.getField('fees_amount').title"
              v-model="stepForm.fees_amount.value"
            >
              <span class="form-group__message__error" v-if="stepForm.fees_amount.message">
                {{ stepForm.fees_amount.message }}
              </span>
            </InputField>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">
          Вы хотите подать ходатайство о рассмотрении дела в Вашем отсутствии?
        </span>
      </div>
      <div class="form-group__body" ref="petition_in_absence">
        <div class="form-group__body__radio">
          <label class="form-group__radio">
            <input type="radio" v-model="stepForm.petition_in_absence.value" :value="1">
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Да</span>
          </label>
          <label class="form-group__radio">
            <input type="radio" v-model="stepForm.petition_in_absence.value" :value="0">
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Нет</span>
          </label>
        </div>
        <span class="form-group__message__error" v-if="stepForm.petition_in_absence.message">
          {{ stepForm.petition_in_absence.message }}
        </span>
      </div>
    </div>
    <div class="form-group" ref="agree">
      <div class="form-group__body">
        <ValidationProvider
          name=" "
          rules="required"
          v-slot="{ errors }"
          style="width: 100%"
        >
          <label class="form-group__radio">
            <input
              type="checkbox"
              v-model="stepForm.agree.value"
              required
              :true-value="1"
              :false-value="null"
            >
            <CheckboxStatesIcon/>
            <span class="form-group__radio__title">Согласен на обработку и хранение персональных данных и
              <router-link :to="{ name: 'confidentiality' }" target="_blank">Политикой конфиденциальности</router-link>
              <br/>
              и <router-link :to="{ name: 'agreement' }" target="_blank">Пользовательским соглашением</router-link>
            </span>
          </label>
          <div class="form-group__message__error" v-if="!stepForm.agree.value && errors[0]">
            Примите условия соглашения
          </div>
          <div class="form-group__message__error" v-else-if="stepForm.agree.value === null && stepForm.agree.message">
            {{ stepForm.agree.message }}
          </div>
        </ValidationProvider>
      </div>
    </div>
    <CheckData/>
  </div>
</template>

<script>
import RadioStatesIcon from 'components/svg/RadioStates.vue'
import CheckboxStatesIcon from 'components/svg/CheckboxStates.vue'
import TooltipIcon from 'components/svg/Tooltip.vue'
import InputField from 'components/InputField.vue'
import { BigNumber } from 'bignumber.js'
import CheckData from './CheckData.vue'
import { updateErrorsMixin } from '@/mixins/updateErrorsMixin.js'
import { resetValidationMixin } from '@/mixins/resetValidationMixin.js'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'DivorceFormStep7',
  mixins: [updateErrorsMixin, resetValidationMixin, scrollToErrorsMixin],
  components: {
    TooltipIcon,
    CheckboxStatesIcon,
    RadioStatesIcon,
    CheckData,
    InputField
  },
  data () {
    return {
      errorsCount: 0,
      stepForm: {
        summ_property: {
          value: null,
          message: null
        },
        fees_cancellation: {
          value: null,
          message: null
        },
        fees_amount: {
          value: null,
          message: null
        },
        petition_in_absence: {
          value: null,
          message: null
        },
        state_duty: {
          value: null,
          message: null
        },
        agree: {
          value: null,
          message: null
        }
      }
    }
  },
  computed: {
    overallSumm () {
      return this.$store.state.stepFormProperties.reduce((total, cur) => {
        if (cur.type.value) {
          switch (cur.type.value.id) {
            case 4:
              return this.summ(total, cur.estate_summ.value)
            case 5:
              return this.summ(total, cur.auto_summ.value)
            case 6:
              return this.summ(total, cur.bank_summ.value)
            case 7:
              return this.summ(total, cur.other_summ.value)
            default:
              return 0
          }
        } else {
          return 0
        }
      }, 0)
    },
    countProperties () {
      let price = new BigNumber(0)
      const claimPrice = 600
      if (this.$store.state.stepFormProperties.length) {
        const overallSumm = new BigNumber(this.overallSumm)
        if (overallSumm.isGreaterThan(0) && overallSumm.isLessThan(20000)) {
          price = price.plus(overallSumm.multipliedBy(0.04).toFixed())
          if (price.isLessThan(400)) {
            price = new BigNumber(400)
          }
        }
        if (overallSumm.isGreaterThanOrEqualTo(20000) && overallSumm.isLessThan(100000)) {
          price = price.plus(overallSumm.minus(20000).multipliedBy(0.03).toFixed()).plus(800)
        }
        if (overallSumm.isGreaterThanOrEqualTo(100000) && overallSumm.isLessThan(200000)) {
          price = price.plus(overallSumm.minus(100000).multipliedBy(0.02).toFixed()).plus(3200)
        }
        if (overallSumm.isGreaterThanOrEqualTo(200000) && overallSumm.isLessThan(1000000)) {
          price = price.plus(overallSumm.minus(200000).multipliedBy(0.01).toFixed()).plus(5200)
        }
        if (overallSumm.isGreaterThanOrEqualTo(1000000)) {
          price = price.plus(overallSumm.minus(1000000).multipliedBy(0.005).toFixed()).plus(13200)
        }
      }
      price = price.plus(claimPrice)
      if (price.isGreaterThan(60000)) {
        price = new BigNumber(60000)
      }
      return price.toFixed(2)
    }
  },
  mounted () {
    this.stepForm.summ_property.value = this.overallSumm
    this.stepForm.state_duty.value = this.countProperties
    if (localStorage.divorceStepForm7) {
      Object.assign(this.stepForm, JSON.parse(localStorage.divorceStepForm7))
    }
  },
  watch: {
    overallSumm (val) {
      this.stepForm.summ_property.value = val
    },
    countProperties (val) {
      this.stepForm.state_duty.value = val
    },
    stepForm: {
      handler: function () {
        localStorage.divorceStepForm7 = JSON.stringify((this.stepForm))
        this.$store.commit(
          'choice_courts_data',
          { key: 'summ_property', value: this.stepForm.summ_property.value }
        )
        this.$store.dispatch('ADD_DIVORCE_STEPFORM', this.stepForm)
      },
      deep: true
    }
  },
  methods: {
    summ () {
      return Array.from(arguments).map((val) => {
        return (val !== null && val.toString().length) ? val : 0
      }).reduce((total, cur) => new BigNumber(total.toString()).plus(cur.toString()), 0).toFixed()
    },
    validate () {
      // TODO сделать валидацию
      return true
    },
    getData () {
      return this.stepForm
    },
    /**
     * Обнуление полей при скрытии из за radio
     * @param fields
     */
    setNull (fields) {
      fields.forEach(field => {
        if (typeof this.stepForm[field].value === 'object' && this.stepForm[field].value !== null) {
          this.stepForm[field].value = []
        } else {
          this.stepForm[field].value = null
        }
      })
    },
    getErrorsCount () {
      return this.errorsCount
    }
  }
}
</script>
