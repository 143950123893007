<template>
  <div>
    <div class="form-group__attention">
      <div class="form-group">
        <div class="form-group__header">
          <span class="form-group__header__title">{{ $store.getters.getField('court_title').title }}</span>
          <div class="form-group__tooltip" v-if="$store.getters.getField('court_title').description">
            <i class="icon" aria-describedby="tooltip" v-tooltip="{ content: $store.getters.getField('court_title').description, trigger: $mq === '700' ? 'click' : 'hover' }" tabindex="1">
              <TooltipIcon/>
            </i>
          </div>
        </div>
        <span
          v-if="choiceCourtsData.live_underage_children
           || choiceCourtsData.unable_participation_court || choiceCourtsData.aliment_claim"
          class="form-group__message"
        >
          Вы должны выбрать суд по месту Вашего жительства
        </span>
        <span v-else class="form-group__message form-group__message__attention">
          Вы должны выбрать суд по месту жительства вашего супруга/ги
        </span>
        <div class="form-group__body">
          <div class="_row">
            <div
              v-if="choiceCourtsData.parent_underage_children ||
              (choiceCourtsData.property_argue && choiceCourtsData.summ_property > 50000)
               || choiceCourtsData.aliment_claim"
              class="_col-12"
            >
              <VSelect
                label="name"
                v-model="numberJudicialSectorId.value"
                :options="localCourts"
                placeholder="Выберите районный суд"
                :searchable="true"
                @search="fetchLocalCourts"
                :filterable="false"
              >
                <template v-slot:no-options="{ _search, searching, loading }">
                  <template v-if="searching">
                    <template v-if="!loading && !localCourts.length">Ничего не найдено</template>
                    <template v-else>Загрузка...</template>
                  </template>
                  <template v-else>Начните вводить адрес и название</template>
                </template>
              </VSelect>
            </div>
            <div v-else class="_col-12">
              <VSelect
                label="name"
                v-model="numberJudicialSectorId.value"
                :options="worldCourts"
                placeholder="Выберите мировой суд"
                :searchable="true"
                @search="fetchWorldCourts"
                :filterable="false"
              >
                <template v-slot:no-options="{ _search, searching, loading }">
                  <template v-if="searching">
                    <template v-if="!loading && !worldCourts.length">Ничего не найдено</template>
                    <template v-else>Загрузка...</template>
                  </template>
                  <template v-else>Начните вводить адрес и название</template>
                </template>
              </VSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TooltipIcon from 'components/svg/Tooltip.vue'

export default {
  name: 'CustomCourts',
  components: {
    TooltipIcon
  },
  props: {
    number_judicial_sector_id: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      numberJudicialSectorId: {
        value: null,
        message: null
      },
      worldCourts: [],
      localCourts: []
    }
  },
  methods: {
    fetchWorldCourts (search, loading) {
      loading(true)
      this.$store.dispatch('GET_WORLDS_COURTS', search).then(({ data }) => {
        this.worldCourts = data.courts
        loading(false)
      })
    },
    fetchLocalCourts (search, loading) {
      loading(true)
      this.$store.dispatch('GET_LOCAL_COURTS', search).then(({ data }) => {
        this.localCourts = data.courts
        loading(false)
      })
    }
  },
  computed: {
    ...mapState({
      choiceCourtsData: 'choiceCourtsData'
    })
  },
  watch: {
    numberJudicialSectorId () {
      this.numberJudicialSectorId = this.number_judicial_sector_id
      this.$emit('changed-number-judical-sector-id', this.numberJudicialSectorId)
    }
  },
  created() {
    this.numberJudicialSectorId = this.number_judicial_sector_id
  }
}
</script>
