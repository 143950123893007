<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('aliment_claim').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('aliment_claim').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: $store.getters.getField('aliment_claim').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body" ref="aliment_claim">
        <div class="form-group__body__radio">
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.aliment_claim.value"
              :value="1"
              @change="setNull(['type', 'monthly_income'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Да</span>
          </label>
          <label class="form-group__radio">
            <input
              type="radio"
              v-model="stepForm.aliment_claim.value"
              :value="0"
              @change="setNull(['type', 'monthly_income'])"
            >
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Нет</span>
          </label>
        </div>
        <span
          class="form-group__message__error"
          v-if="stepForm.aliment_claim.value === null && stepForm.aliment_claim.message"
        >
          {{ stepForm.aliment_claim.message }}
        </span>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.aliment_claim.value === 1">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('type').title }}</span>
        <span class="form-group__tooltip" v-if="$store.getters.getField('type').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: $store.getters.getField('type').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body" ref="type">
        <div class="form-group__body__radio">
          <div class="_row">
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.type.value" :value="1">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ $store.getters.getField('type_one').title }}</span>
                <div class="form-group__tooltip" v-if="$store.getters.getField('type_one').description">
                  <i
                    class="icon"
                    aria-describedby="tooltip"
                    v-tooltip="{ content: $store.getters.getField('type_one').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                    tabindex="1"
                  >
                    <TooltipIcon/>
                  </i>
                </div>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.type.value" :value="2">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ $store.getters.getField('type_two').title }}</span>
                <div class="form-group__tooltip" v-if="$store.getters.getField('type_two').description">
                  <i
                    class="icon"
                    aria-describedby="tooltip"
                    v-tooltip="{ content: $store.getters.getField('type_two').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                    tabindex="1"
                  >
                    <TooltipIcon/>
                  </i>
                </div>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.type.value" :value="3">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ $store.getters.getField('type_three').title }}</span>
                <div class="form-group__tooltip" v-if="$store.getters.getField('type_three').description">
                  <i
                    class="icon"
                    aria-describedby="tooltip"
                    v-tooltip="{ content: $store.getters.getField('type_three').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                    tabindex="1"
                  >
                    <TooltipIcon/>
                  </i>
                </div>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.type.value" :value="4">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ $store.getters.getField('type_four').title }}</span>
                <div class="form-group__tooltip" v-if="$store.getters.getField('type_four').description">
                  <i
                    class="icon"
                    aria-describedby="tooltip"
                    v-tooltip="{ content: $store.getters.getField('type_four').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                    tabindex="1"
                  >
                    <TooltipIcon/>
                  </i>
                </div>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.type.value" :value="5">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ $store.getters.getField('type_five').title }}</span>
                <div class="form-group__tooltip" v-if="$store.getters.getField('type_five').description">
                  <i
                    class="icon"
                    aria-describedby="tooltip"
                    v-tooltip="{ content: $store.getters.getField('type_five').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                    tabindex="1"
                  >
                    <TooltipIcon/>
                  </i>
                </div>
              </label>
            </div>
            <div class="_col-12" v-if="stepForm.type.value === 3 || stepForm.type.value === 5">
              <ValidationProvider
                name=" "
                rules="required"
                v-slot="{ errors }"
                style="width: 100%"
              >
                <InputField
                  :type="'number'"
                  :required="true"
                  :inputmode="'decimal'"
                  :min="0"
                  :title="$store.getters.getField('monthly_income').title"
                  :placeholder="$store.getters.getField('monthly_income').title"
                  v-model="stepForm.monthly_income.value"
                >
                  <span class="form-group__message__error" v-if="!stepForm.monthly_income.message">
                    {{ errors[0] }}
                  </span>
                  <span
                    class="form-group__message__error"
                    v-if="!stepForm.monthly_income.value && stepForm.monthly_income.message"
                  >
                    {{ stepForm.monthly_income.message }}
                  </span>
                </InputField>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <span class="form-group__message__error" v-if="stepForm.type.value === null && stepForm.type.message">
          {{ stepForm.type.message }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import RadioStatesIcon from 'components/svg/RadioStates.vue'
import TooltipIcon from 'components/svg/Tooltip.vue'
import InputField from 'components/InputField.vue'
import { updateErrorsMixin } from '@/mixins/updateErrorsMixin.js'
import { resetValidationMixin } from '@/mixins/resetValidationMixin.js'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'DivorceFormStep5',
  mixins: [updateErrorsMixin, resetValidationMixin, scrollToErrorsMixin],
  components: {
    TooltipIcon,
    RadioStatesIcon,
    InputField
  },
  data () {
    return {
      errorsCount: 0,
      stepForm: {
        type: {
          value: null,
          message: null
        },
        monthly_income: {
          value: null,
          message: null
        },
        aliment_claim: {
          value: null,
          message: null
        }
      },
      validator: {
        alimentType: false
      }
    }
  },
  mounted () {
    if (localStorage.divorceStepForm5) {
      Object.assign(this.stepForm, JSON.parse(localStorage.divorceStepForm5))
    }
  },
  watch: {
    stepForm: {
      handler: function () {
        if ((this.stepForm.type.value !== 3) && (this.stepForm.type.value !== 5)) {
          this.stepForm.monthly_income.value = null
        }
        localStorage.divorceStepForm5 = JSON.stringify((this.stepForm))
        this.$store.dispatch('ADD_DIVORCE_STEPFORM', this.stepForm)
        this.$store.commit('choice_courts_data', {
          key: 'aliment_claim',
          value: this.stepForm.aliment_claim.value
        })
      },
      deep: true
    }
  },
  methods: {
    validate () {
      // TODO сделать валидацию
      return true
    },
    setNull (fields) {
      fields.forEach(field => {
        if (typeof this.stepForm[field].value === 'object' && this.stepForm[field].value !== null) {
          this.stepForm[field].value = []
        } else {
          this.stepForm[field].value = null
        }
      })
    },
    getData () {
      return this.stepForm
    },
    /**
     * Обнуление полей при скрытии из за radio
     * @param fields
     */
    getErrorsCount () {
      return this.errorsCount
    }
  }
}
</script>
