<template>
  <div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('fio_spouse_title').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('fio_spouse_title').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: $store.getters.getField('fio_spouse_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body">
        <div class="_row">
          <div class="_col-4" ref="spouse_surname">
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                :required="true"
                :title="$store.getters.getField('spouse_surname').title"
                :placeholder="$store.getters.getField('spouse_surname').title"
                v-model="stepForm.spouse_surname.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.spouse_surname.message">{{ errors[0] }}</span>
                <span
                  class="form-group__message__error"
                  v-if="!stepForm.spouse_surname.value && stepForm.spouse_surname.message"
                >
                  {{ stepForm.spouse_surname.message }}
                </span>
              </InputField>

            </ValidationProvider>
          </div>
          <div class="_col-4" ref="spouse_name">
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                :required="true"
                :title="$store.getters.getField('spouse_name').title"
                :placeholder="$store.getters.getField('spouse_name').title"
                v-model="stepForm.spouse_name.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.spouse_name.message">{{ errors[0] }}</span>
                <span
                  class="form-group__message__error"
                  v-if="!stepForm.spouse_name.value && stepForm.spouse_name.message"
                >
                  {{ stepForm.spouse_name.message }}
                </span>
              </InputField>
            </ValidationProvider>
          </div>
          <div class="_col-4" ref="spouse_patronymic">
            <InputField
              :required="true"
              :title="$store.getters.getField('spouse_patronymic').title"
              :placeholder="$store.getters.getField('spouse_patronymic').title"
              v-model="stepForm.spouse_patronymic.value"
            >
            <span class="form-group__message__error" v-if="stepForm.spouse_patronymic.message">
              {{ stepForm.spouse_patronymic.message }}
            </span>
            </InputField>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('address_spouse_title').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('address_spouse_title').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: $store.getters.getField('address_spouse_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body" ref="spouse_addresss">
        <div class="_row spouse-address">
          <div class="_col-8">
            <VSelect
              label="value"
              :disabled="spouseAddressSimilar"
              ref="spouse_address"
              v-model="stepForm.spouse_address.value"
              :options="addresses"
              :placeholder="'Место жительства'"
              :searchable="true"
              @search="fetchAddress"
              @search:focus="spouseAddressFocus"
              @search:blur="spouseAddressFocus"
              :filterable="false"
            >
              <template v-slot:no-options="{ _search, searching, loading }">
                <template v-if="searching">
                  <template v-if="!loading && !addresses.length">Ничего не найдено</template>
                  <template v-else>Загрузка...</template>
                </template>
                <template v-else>Начните вводить адрес</template>
              </template>
            </VSelect>
            <span class="form-group__message__error" v-if="!stepForm.spouse_address.value && validator.spouseAddress && !stepForm.spouse_address.message">
              Поле обязательно для заполнения
            </span>
            <span
              class="form-group__message__error"
              v-if="!stepForm.spouse_address.value && stepForm.spouse_address.message">
              {{ stepForm.spouse_address.message }}
            </span>
          </div>
          <div class="_col-4">
            <label class="form-group__radio divorce_form-group__radio--similar">
              <input type="checkbox" v-model="spouseAddressSimilar">
              <CheckboxStatesIcon/>
              <span class="form-group__radio__title">{{ $store.getters.getField('match_my_address').title }}</span>
              <span class="form-group__tooltip" v-if="$store.getters.getField('match_my_address').description">
                <i class="icon" aria-describedby="tooltip"
                   v-tooltip="{ content: $store.getters.getField('match_my_address').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                   tabindex="1">
                  <TooltipIcon/>
                </i>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('birthday_title').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('birthday_title').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: $store.getters.getField('birthday_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body">
        <div class="_row">
          <div class="_col-4">
            <DatePickerComponent
              class="w-100"
              v-if="mount"
              v-model="stepForm.spouse_birthday.value"
            />
            <span class="form-group__message__error" v-if="stepForm.spouse_birthday.message">
              {{ stepForm.spouse_birthday.message }}
            </span>
          </div>

        <div class="_col-8" ref="spouse_birth_place">
          <InputField
            :required="true"
            :title="$store.getters.getField('spouse_birth_place').title"
            :placeholder="$store.getters.getField('spouse_birth_place').title"
            v-model="stepForm.spouse_birth_place.value"
          >
              <span class="form-group__message__error" v-if="stepForm.spouse_birth_place.message">
                {{ stepForm.spouse_birth_place.message }}
              </span>
          </InputField>
        </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('work_spouse_title').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('work_spouse_title').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: $store.getters.getField('work_spouse_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body">
        <div class="_row">
          <div class="_col-12" ref="spouse_work">
            <InputField
              :required="true"
              :title="$store.getters.getField('spouse_work').title"
              :placeholder="$store.getters.getField('spouse_work').title"
              v-model="stepForm.spouse_work.value"
            >
              <span class="form-group__message__error" v-if="stepForm.spouse_work.message">
                {{ stepForm.spouse_work.message }}
              </span>
            </InputField>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from 'components/svg/Tooltip.vue'
import InputField from 'components/InputField.vue'
import CheckboxStatesIcon from 'components/svg/CheckboxStates.vue'
import { updateErrorsMixin } from '@/mixins/updateErrorsMixin.js'
import { resetValidationMixin } from '@/mixins/resetValidationMixin.js'
import { fetchAddressMixin } from '@/mixins/fetchAddressMixin.js'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'step2',
  mixins: [updateErrorsMixin, resetValidationMixin, fetchAddressMixin, scrollToErrorsMixin],
  components: {
    DatePickerComponent: () => import('@/components/DatePickerComponent.vue'),
    CheckboxStatesIcon,
    TooltipIcon,
    InputField
  },
  data () {
    return {
      mount: false,
      spouseAddressSimilar: false,
      errorsCount: 0,
      addresses: [],
      stepForm: {
        spouse_surname: {
          value: null,
          message: null
        },
        spouse_name: {
          value: null,
          message: null
        },
        spouse_patronymic: {
          value: null,
          message: null
        },
        spouse_address: {
          value: null,
          message: null
        },
        spouse_birthday: {
          value: null,
          message: null
        },
        spouse_birth_place: {
          value: null,
          message: null
        },
        spouse_work: {
          value: null,
          message: null
        }
      },
      validator: {
        spouseAddress: false
      }
    }
  },
  computed: {
    anotherSpouseAddress () {
      return this.$store.state.divorceStepForm.address || ''
    }
  },
  methods: {
    spouseAddressFocus () {
      this.validator.spouseAddress = !this.stepForm.spouse_address.value

      if (this.stepForm.spouse_address.value) {
        this.$refs.spouse_address.search = this.stepForm.spouse_address.value.value
      }
    },
    getData () {
      return this.stepForm
    },
    getErrorsCount () {
      return this.errorsCount
    },
    validate () {
      // TODO сделать валидацию
      return true
    }
  },
  watch: {
    'spouseAddressSimilar' (val) {
      if (val) {
        if (!this.anotherSpouseAddress.value) return
        this.stepForm.spouse_address.value = this.anotherSpouseAddress.value
        this.$refs.spouse_address.search = this.anotherSpouseAddress.value.value
      } else {
        this.stepForm.spouse_address.value = null
        this.$refs.spouse_address.search = ''
        this.addresses = []
      }
    },
    stepForm: {
      handler: function () {
        localStorage.setItem('divorceStepForm2', JSON.stringify((this.stepForm)))
        this.$store.dispatch('ADD_DIVORCE_STEPFORM', this.stepForm)
      },
      deep: true
    }
  },
  mounted () {
    this.mount = true
    if (localStorage.divorceStepForm2) {
      Object.assign(this.stepForm, JSON.parse(localStorage.divorceStepForm2))
    }
  }
}
</script>
