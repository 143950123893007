<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('fio_title').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('fio_title').description">
          <i
            class="icon" aria-describedby="tooltip"
            v-tooltip="{ content: $store.getters.getField('fio_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body">
        <div class="_row">
          <div class="_col-4" ref="surname">
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                :required="true"
                :title="$store.getters.getField('surname').title"
                :placeholder="$store.getters.getField('surname').title"
                v-model="stepForm.surname.value"
                :autofocus="true"
              >
                <span class="form-group__message__error" v-if="!stepForm.surname.message">
                  {{ errors[0] }}
                </span>
                <span class="form-group__message__error" v-if="!stepForm.surname.value && stepForm.surname.message">
                  {{ stepForm.surname.message }}
                </span>
              </InputField>
            </ValidationProvider>
          </div>
          <div class="_col-4" ref="name">
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                :required="true"
                :title="$store.getters.getField('name').title"
                :placeholder="$store.getters.getField('name').title"
                v-model="stepForm.name.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.name.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="!stepForm.name.value && stepForm.name.message">
                  {{ stepForm.name.message }}
                </span>
              </InputField>
            </ValidationProvider>
          </div>
          <div class="_col-4" ref="patronymic">
            <InputField
              :required="true"
              :title="$store.getters.getField('patronymic').title"
              :placeholder="$store.getters.getField('patronymic').title"
              v-model="stepForm.patronymic.value"
            >
              <span class="form-group__message__error" v-if="stepForm.patronymic.message">
                {{ stepForm.patronymic.message }}
              </span>
            </InputField>
          </div>
          <div class="_col-4" ref="email">
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                :type="'email'"
                :required="true"
                :title="$store.getters.getField('email').title"
                :placeholder="$store.getters.getField('email').title"
                v-model="stepForm.email.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.email.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="stepForm.email.message">
                  {{ stepForm.email.message }}
                </span>
              </InputField>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('birthday_and_birthplace').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('birthday_and_birthplace').description">
          <i
            class="icon" aria-describedby="tooltip"
            v-tooltip="{ content: $store.getters.getField('birthday_and_birthplace').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body">
        <div class="_row">
          <div class="_col-4" ref="birthday">
            <DatePickerComponent
              class="w-100"
              v-if="mount"
              v-model="stepForm.birthday.value"
            />
            <span class="form-group__message__error" v-if="!stepForm.birthday.value && stepForm.birthday.message">
              {{ stepForm.birthday.message }}
            </span>
          </div>
          <div class="_col-8" ref="birthplace">
            <InputField
              :required="true"
              :title="$store.getters.getField('birthplace').title"
              :placeholder="$store.getters.getField('birthplace').title"
              v-model="stepForm.birthplace.value"
            >
              <span class="form-group__message__error" v-if="validator.birthplace && !stepForm.birthplace.message">
                Поле обязательно для заполнения
              </span>
              <span class="form-group__message__error" v-if="!stepForm.birthplace.value && stepForm.birthplace.message">
                {{ stepForm.birthplace.message }}
              </span>
            </InputField>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('document_type').title }}</span>
      </div>
      <div class="form-group__body">
        <div class="form-group__body__radio">
          <div class="_row">
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.document_type.value" :value="1" @click="clearDocumentNumber">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ $store.getters.getField('document_type_one').title }}</span>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.document_type.value" :value="2" @click="clearDocumentNumber">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ $store.getters.getField('document_type_two').title }}</span>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.document_type.value" :value="3" @click="clearDocumentNumber">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ $store.getters.getField('document_type_three').title }}</span>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.document_type.value" :value="4" @click="clearDocumentNumber">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ $store.getters.getField('document_type_four').title }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.document_type.value">
      <div class="form-group__body">
        <div class="_row">
          <div class="_col-12" ref="document_number">
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                v-if="stepForm.document_type.value === 1"
                :required="true"
                v-mask="'###-###-### ##'"
                :title="$store.getters.getField('document_number').title"
                :placeholder="$store.getters.getField('document_number').title"
                v-model="stepForm.document_number.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.document_number.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="!stepForm.document_number.value && stepForm.document_number.message">
                  {{ stepForm.document_number.message }}
                </span>
              </InputField>
            </ValidationProvider>
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                v-if="stepForm.document_type.value === 2"
                :required="true"
                v-mask="'############'"
                :title="$store.getters.getField('document_number').title"
                :placeholder="$store.getters.getField('document_number').title"
                v-model="stepForm.document_number.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.document_number.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="!stepForm.document_number.value && stepForm.document_number.message">
                  {{ stepForm.document_number.message }}
                </span>
              </InputField>
            </ValidationProvider>
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                v-if="stepForm.document_type.value === 3"
                :required="true"
                v-mask="'## ## ######'"
                :title="$store.getters.getField('document_number').title"
                :placeholder="$store.getters.getField('document_number').title"
                v-model="stepForm.document_number.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.document_number.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="!stepForm.document_number.value && stepForm.document_number.message">
                  {{ stepForm.document_number.message }}
                </span>
              </InputField>
            </ValidationProvider>
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                v-if="stepForm.document_type.value === 4"
                :required="true"
                v-mask="'## ## ######'"
                :title="$store.getters.getField('document_number').title"
                :placeholder="$store.getters.getField('document_number').title"
                v-model="stepForm.document_number.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.document_number.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="!stepForm.document_number.value && stepForm.document_number.message">
                  {{ stepForm.document_number.message }}
                </span>
              </InputField>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('is_mailing_address').title }}</span>
      </div>
      <div class="form-group__body">
        <div class="form-group__body__radio">
          <label class="form-group__radio">
            <input type="radio" v-model="stepForm.is_mailing_address.value" :value="1" @click="clearAddressMailing">
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Да</span>
          </label>
          <label class="form-group__radio">
            <input type="radio" v-model="stepForm.is_mailing_address.value" :value="0" @click="clearAddressMailing">
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Нет</span>
          </label>
        </div>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.is_mailing_address.value">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('passport_address').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('passport_address').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: $store.getters.getField('passport_address').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body" ref="addresss">
        <div class="_row">
          <div class="_col-12">
            <VSelect
              label="value"
              ref="address"
              v-model="stepForm.address.value"
              :options="addresses"
              :placeholder="$store.getters.getField('address').title"
              :searchable="true"
              @search="fetchAddress"
              @search:focus="addressFocus"
              @search:blur="addressFocus"
              :filterable="false"
            >
              <template v-slot:no-options="{ _search, searching, loading }">
                <template v-if="searching">
                  <template v-if="!loading && !addresses.length">Ничего не найдено</template>
                  <template v-else>Загрузка...</template>
                </template>
                <template v-else>Начните вводить адрес</template>
              </template>
            </VSelect>
            <span class="form-group__message__error" v-if="validator.address && !stepForm.address.message">
              Поле обязательно для заполнения
            </span>
            <span class="form-group__message__error" v-if="!stepForm.address.value && stepForm.address.message">
              {{ stepForm.address.message }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.is_mailing_address.value === 0">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('address_type').title }}</span>
      </div>
      <div class="form-group__body">
        <div class="form-group__body__radio">
          <div class="_row">
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.address_type.value" :value="1" @click="clearAddressType">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">Место жительства по паспорту (прописка)</span>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.address_type.value" :value="2" @click="clearAddressType">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">Текущее место жительства - место фактического пребывания</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.address_type.value !== null">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('address').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('address').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: $store.getters.getField('address').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body" ref="addresss">
        <div class="_row">
          <div class="_col-12">
            <VSelect
              label="value"
              ref="address"
              v-model="stepForm.address.value"
              :options="addresses"
              :placeholder="$store.getters.getField('address').title"
              :searchable="true"
              @search="fetchAddress"
              @search:focus="addressFocus"
              @search:blur="addressFocus"
              :filterable="false"
            >
              <template v-slot:no-options="{ _search, searching, loading }">
                <template v-if="searching">
                  <template v-if="!loading && !addresses.length">Ничего не найдено</template>
                  <template v-else>Загрузка...</template>
                </template>
                <template v-else>Начните вводить адрес</template>
              </template>
            </VSelect>
            <span class="form-group__message__error" v-if="validator.address && !stepForm.address.message">
              Поле обязательно для заполнения
            </span>
            <span class="form-group__message__error" v-if="!stepForm.address.value && stepForm.address.message">
              {{ stepForm.address.message }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.address_type.value === 2">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ $store.getters.getField('mailing_address').title }}</span>
        <div class="form-group__tooltip" v-if="$store.getters.getField('mailing_address').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: $store.getters.getField('mailing_address').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body" ref="mailing_addresss">
        <div class="_row">
          <div class="_col-12">
            <VSelect
              label="value"
              ref="mailing_address"
              v-model="stepForm.mailing_address.value"
              :options="addresses"
              :placeholder="$store.getters.getField('mailing_address').title"
              :searchable="true"
              @search="fetchAddress"
              @search:focus="addressFocus"
              @search:blur="addressFocus"
              :filterable="false"
            >
              <template v-slot:no-options="{ _search, searching, loading }">
                <template v-if="searching">
                  <template v-if="!loading && !addresses.length">Ничего не найдено</template>
                  <template v-else>Загрузка...</template>
                </template>
                <template v-else>Начните вводить адрес</template>
              </template>
            </VSelect>
            <span class="form-group__message__error" v-if="validator.mailing_address && !stepForm.mailing_address.message">
              Поле обязательно для заполнения
            </span>
            <span class="form-group__message__error" v-if="!stepForm.mailing_address.value && stepForm.mailing_address.message">
              {{ stepForm.mailing_address.message }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <CustomCourts
      v-if="showCustomCourts"
      :number_judicial_sector_id="stepForm.number_judicial_sector_id"
      @changed-number-judical-sector-id="numberJudicalSectorIdChanged"
    />
  </div>
</template>

<script>
import TooltipIcon from 'components/svg/Tooltip.vue'
import InputField from 'components/InputField.vue'
import CustomCourts from '../components/CustomCourts.vue'
import RadioStatesIcon from 'components/svg/RadioStates.vue'
import petrovich from 'petrovich'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import { updateErrorsMixin } from '@/mixins/updateErrorsMixin.js'
import { resetValidationMixin } from '@/mixins/resetValidationMixin.js'
import { fetchAddressMixin } from '@/mixins/fetchAddressMixin.js'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'DivorceFormStep1',
  mixins: [updateErrorsMixin, resetValidationMixin, fetchAddressMixin, scrollToErrorsMixin],
  components: {
    DatePickerComponent: () => import('@/components/DatePickerComponent.vue'),
    RadioStatesIcon,
    TooltipIcon,
    InputField,
    CustomCourts
  },
  props: {
    showCustomCourts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mount: false,
      timeout: null,
      addresses: [],
      document_type: null,
      errorsCount: 0,
      stepForm: {
        number_judicial_sector_id: {
          value: null,
          message: null
        },
        world_courts: {
          value: null,
          message: null
        },
        local_courts: {
          value: null,
          message: null
        },
        region: {
          value: null,
          message: null
        },
        surname: {
          value: null,
          message: null
        },
        name: {
          value: null,
          message: null
        },
        patronymic: {
          value: null,
          message: null
        },
        email: {
          value: null,
          message: null
        },
        address: {
          value: null,
          message: null
        },
        document_type: {
          value: null,
          message: null
        },
        document_number: {
          value: null,
          message: null
        },
        is_mailing_address: {
          value: null,
          message: null
        },
        address_type: {
          value: null,
          message: null
        },
        mailing_address: {
          value: null,
          message: null
        },
        birthday: {
          value: null,
          message: null
        },
        birthplace: {
          value: null,
          message: null
        }
      },
      validator: {
        address: false
      }
    }
  },
  mounted () {
    this.mount = true
    if (localStorage.divorceStepForm1) {
      Object.assign(this.stepForm, JSON.parse(localStorage.divorceStepForm1))
      this.stepForm.number_judicial_sector_id = { value: null, message: null }
    }
    if (localStorage.document_number) {
      this.stepForm.document_number.value = localStorage.document_number
    }
  },
  watch: {
    'stepForm.address.value' (val) {
      if (!val) return
      let string = ''
      let genitive = ''
      if (val.data.region_type_full.toLowerCase() === 'город' ||
        val.data.region.toLowerCase() === 'ямало-ненецкий' ||
        val.data.region.toLowerCase().indexOf(' область') !== -1 ||
        val.data.region.toLowerCase().indexOf(' округ') !== -1 ||
        val.data.region.toLowerCase().indexOf(' автономный округ') !== -1 ||
        val.data.region.toLowerCase().indexOf(' республика') !== -1) {
        this.stepForm.region.value = val.data.region_with_type
      } else if (val.data.region_type_full.toLowerCase() === 'республика') {
        if (val.data.region.toLowerCase() === 'чеченская' ||
          val.data.region.toLowerCase() === 'карачаево-черкесская' ||
          val.data.region.toLowerCase() === 'удмуртская' ||
          val.data.region.toLowerCase() === 'чувашская' ||
          val.data.region.toLowerCase() === 'кабардино-балкарская') {
          genitive = petrovich({
            last: val.data.region,
            first: val.data.region_type_full,
            gender: 'female'
          }, 'prepositional')
        } else {
          genitive = petrovich({
            last: val.data.region_type_full,
            first: val.data.region,
            gender: 'female'
          }, 'prepositional')
        }
        this.stepForm.region.value = `${this.capitalize(genitive.last)} ${this.capitalize(genitive.first)}`
      } else {
        string = `${val.data.region} ${val.data.region_type_full}`
        if (string.indexOf('ая ') !== -1) {
          genitive = petrovich({
            last: val.data.region,
            first: val.data.region_type_full,
            gender: 'female'
          }, 'prepositional')
        } else {
          genitive = petrovich({
            last: val.data.region,
            first: val.data.region_type_full,
            gender: 'male'
          }, 'prepositional')
        }
        this.stepForm.region.value = `${this.capitalize(genitive.last)} ${this.capitalize(genitive.first)}`
      }
    },
    stepForm: {
      handler: function () {
        localStorage.document_number = this.document_number
        localStorage.divorceStepForm1 = JSON.stringify((this.stepForm))
        this.$store.dispatch('ADD_DIVORCE_STEPFORM', this.stepForm)
      },
      deep: true
    }
  },
  methods: {
    scrollToCourts () {
      scrollIntoView(this.$refs.courts, {
        behavior: 'smooth',
        block: 'start'
      })
    },
    clearDocumentNumber () {
      this.stepForm.document_number.value = null
    },
    clearAddressMailing () {
      this.stepForm.address.value = null
      this.stepForm.mailing_address.value = null
      this.stepForm.address_type.value = null
    },
    clearAddressType () {
      this.stepForm.address.value = null
      this.stepForm.mailing_address.value = null
    },
    capitalize (string) {
      if (typeof string === 'string') {
        return string.charAt(0).toUpperCase() + string.slice(1)
      } else {
        return string
      }
    },
    validate () {
      // TODO сделать валидацию
      return true
    },
    addressFocus () {
      if (this.stepForm.address.value) {
        this.$refs.address.search = this.stepForm.address.value.value
      }

      this.validator.address = !this.stepForm.address.value
    },
    getData () {
      return this.stepForm
    },
    getErrorsCount () {
      return this.errorsCount
    },
    numberJudicalSectorIdChanged (event) {
      this.stepForm.number_judicial_sector_id = event
    }
  }
}
</script>
