<template>
  <form class="main main--internal" ref="start" autocomplete="off" @submit.prevent="submitForm">
    <noscript>Включите JavaScript чтобы воспользоваться этим ботом</noscript>
    <div class="page-header__container divorce_page-header__container">
      <h1 class="page-header">{{ navigator.title }}</h1>
      <StepBadge class="step-badge divorce_step-badge" :current-step="currentStep" :max-steps="maxSteps" @change="setStep"/>
    </div>
    <span class="page-header__subtitle">{{ steps[currentStep] }}</span>
    <div class="steps-form">
      <DivorceFormStep1 :key="0" v-show="currentStep === 0" ref="step0" :show-custom-courts="showCustomCourts" />
      <DivorceFormStep2 :key="1" v-show="currentStep === 1" ref="step1" />
      <!-- <DivorceFormStep3 :key="2" v-show="currentStep === 2" ref="step2" /> -->
      <DivorceFormStep4 :key="2" v-show="currentStep === 2" ref="step2" @show="setShowAliments" />
      <DivorceFormStep5 :key="3" v-show="currentStep === 3 && showAliments" ref="step3" />
      <DivorceFormStep6 :key="4" v-show="currentStep === 4" ref="step4" />
      <DivorceFormStep7 :key="5" v-show="currentStep === 5" ref="step5" />
      <div class="steps-form__footer">
        <div class="steps-form__buttons">
          <button
            type="button"
            class="steps-prev button"
            :class="{
              'button--hollow': currentStep === 0,
              'button--fill': currentStep !== 0,
            }"
            @click="prevStep"
          >
            Назад
          </button>
          <button type="button" class="steps-next button button--fill" @click="nextStep">
            {{ nextStepButtonTitle }}
          </button>
        </div>
        <div v-if="this.currentStep === this.maxSteps" class="form-group__header__title">
          Мы гарантируем конфиденциальность внесенных данных, а также возврат денег, если суд не примет наш иск.
          Есть вопросы и предложения? Мы с удовольствием их примем по тел. <a href="tel:+79882727273">+7 988 272 72 73</a> или support@sudbot.ru.
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import StepBadge from 'components/StepBadge.vue'
import DivorceFormStep1 from './steps/step1.vue'
import DivorceFormStep2 from './steps/step2.vue'
// import DivorceFormStep3 from './steps/step3.vue'
import DivorceFormStep4 from './steps/step4.vue'
import DivorceFormStep5 from './steps/step5.vue'
import DivorceFormStep6 from './steps/step6.vue'
import DivorceFormStep7 from './steps/step7.vue'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'DivorceBotPage',
  mixins: [scrollToErrorsMixin],
  components: {
    StepBadge,
    DivorceFormStep1,
    DivorceFormStep2,
    // DivorceFormStep3,
    DivorceFormStep4,
    DivorceFormStep5,
    DivorceFormStep6,
    DivorceFormStep7
  },
  async asyncData ({ store }) {
    await store.dispatch('GET_DIVORCE_BOT_PAGE')
  },
  data () {
    return {
      currentStep: 0,
      maxSteps: 5,
      forms: [],
      errors: {},
      showAliments: false,
      showCustomCourts: false
    }
  },
  computed: {
    navigator () {
      return this.$store.state.divorce_bot_page.navigator
    },
    bot () {
      return this.$store.state.divorce_bot_page.bot
    },
    nextStepButtonTitle () {
      return this.currentStep !== this.maxSteps
        ? 'Далее'
        : 'Получить документ: ' + this.bot.price + '₽'
    },
    steps () {
      return [
        this.$store.getters.getField('step1_title').title,
        'Данные супруга/ги',
        //'Данные свидетельства о браке',
        this.$store.getters.getField('children_title').title,
        this.$store.getters.getField('alimony_title').title,
        this.$store.getters.getField('property_division_title').title,
        this.$store.getters.getField('state_duty_title').title
      ]
    }
  },
  beforeCreate: function () {
    this.$store.commit('choice_courts_data_delete')
  },
  methods: {
    setShowAliments (bool) {
      this.showAliments = bool
    },
    setStep (index) {
      if (index === 4 && !this.showAliments) {
        return
      }
      this.currentStep = index
    },
    submitForm () {
      this.resetValidation()
      const data = new FormData()
      const form = this.$store.state.divorceStepForm
      Object.keys(form).forEach(field => {
        if (field === 'address' || field === 'spouse_address' || field === 'mailing_address') {
          if (form[field].value !== null) data.append(field, form[field].value.unrestricted_value)
        } else if (field === 'underage_children') {
          if (form.have_underage_children.value) {
            form[field].value.forEach((item, index) => {
              Object.keys(item).forEach(fld => {
                if (item[fld].value !== null) {
                  data.append(`${field}[${index}][${fld}]`, item[fld].value)
                }
              })
            })
          }
        } else if (field === 'properties') {
          form[field].value.forEach((item, index) => {
            Object.keys(item).forEach(fld => {
              if (typeof item[fld].value === 'object') {
                if (item[fld].value !== null) {
                  data.append(`${field}[${index}][${fld}]`, item[fld].value.id)
                }
              } else {
                if (item[fld].value !== null) {
                  data.append(`${field}[${index}][${fld}]`, item[fld].value)
                }
              }
            })
          })
        } else if (typeof form[field].value === 'boolean') {
          data.append(field, form[field].value ? '1' : '0')
        } else if (typeof form[field].value === 'object') {
          if (form[field].value !== null) {
            data.append(field, form[field].value.id)
          }
        } else {
          if (form[field].value !== null) {
            data.append(field, form[field].value)
          }
        }
      })
      this.$store.dispatch('POST_DIVORCE_BOT', data).then(res => {
        // if (res.data.link) {
        // const link = document.createElement('a')
        // link.setAttribute('href', this.$store.state.api + res.data.link)
        // link.setAttribute('download', this.$store.state.api + res.data.link)
        // link.click()
        // }
        if (res.data.url) {
          location.href = res.data.url
        }
        this.$store.commit('choice_courts_data_delete')
      }).catch(({ response }) => {
        if (response.status === 423) {
          if (response.data) {
            this.$swal({
              title: 'По Вашему адресу нет судебного участка.',
              text: 'Указать суд вручную?',
              icon: 'warning',
              buttons: {
                cancel: 'Нет',
                catch: {
                  text: 'Да',
                  value: 'catch'
                }
              }
            })
              .then((value) => {
                switch (value) {
                  case 'catch':
                    this.showCustomCourts = true
                    this.$refs.step0.scrollToCourts()
                    break

                  default:
                    break
                }
              })
          } else {
            this.$swal('По Вашему адресу нет судебного участка.')
          }
          this.currentStep = 0
        } else if (response.status === 500) {
          this.$swal('Произошла ошибка, обратитесь в тех. поддержку')
        } else {
          this.$swal('Пожалуйста, проверьте правильность заполненных полей!')
          // Вывод ошибок валидаций от Laravel
          for (let i = 0; i <= this.maxSteps; i++) {
            this.$refs['step' + i].updateErrors(response.data)
          }
          new Promise((resolve, reject) => {
            this.$nextTick(() => {
              for (let i = 0; i <= this.maxSteps; i++) {
                if (this.$refs['step' + i].getErrorsCount() > 0) {
                  this.currentStep = i
                  resolve()
                }
              }
            })
          }).then(() => {
            const stepForm = this.$refs['step' + this.currentStep].stepForm
            Object.keys(stepForm).forEach(field => {
              if (stepForm[field].message) {
                return this.$refs['step' + this.currentStep].scrollToErrors(field)
              }
              if (stepForm[field].value && typeof stepForm[field].value.forEach === 'function') {
                let count = null
                stepForm[field].value.forEach((fld, index) => {
                  Object.keys(fld).forEach(key => {
                    if (fld[key] && fld[key].message) {
                      count = index
                    }
                  })
                })
                if (count !== null) return this.$refs['step' + this.currentStep].scrollToCountedErrors(field, count)
              }
            })
          })
        }
      })
    },
    resetValidation () {
      this.errors = {}
      for (let i = 0; i <= this.maxSteps; i++) {
        this.$refs['step' + i].resetValidation()
      }
    },
    prevStep () {
      if (this.currentStep === 4 && !this.showAliments) {
        this.currentStep = 2
      } else {
        this.currentStep > 0 ? this.currentStep-- : (this.currentStep = 0)
      }
    },
    nextStep () {
      if (this.currentStep === this.maxSteps) {
        this.submitForm()
      } else {
        if (this.$refs['step' + this.currentStep].validate()) {
          if (this.currentStep === 2 && !this.showAliments) {
            this.currentStep = 4
          } else if (this.currentStep < this.maxSteps) {
            this.currentStep++
            this.scrollToErrors('start')
          } else this.currentStep = this.maxSteps
        }
      }
    }
  },
  metaInfo () {
    const navigator = this.$store.state.divorce_bot_page.navigator
    return this.$seo(
      'common',
      navigator.title,
      navigator.meta_keywords,
      navigator.description,
      navigator.title,
      null,
      navigator.description
    )
  }
}
</script>

